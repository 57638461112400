export default {
	namespaced: true,
	state: {
		PROGRESS_TYPE_STEPS: 'steps',
		PROGRESS_TYPE_RANGES: 'ranges',
		PROGRESS_TYPE_SINGLE: 'single',
		PROGRESS_TYPE_SLIDER: 'slider',

		PROGRESS_MAP_TYPE:{
			'lvl'			: 	'steps',
			'lvl_dv'		: 	'steps',
			'lvl_dv_slider'	: 	'slider',
			'lvl_single'	: 	'steps',
			'lvl_slider'	: 	'slider',
			'range'			: 	'ranges',
			'slider'		: 	'single',
		},

		serviceRanges: [],
		serviceProgressCnf:{},

		progressType: null,

		loadingGetServiceRanges: false
	},

	mutations: {
		BUILD_SERVICE_RANGES(state, respCnf){
			state.progressType = state.PROGRESS_MAP_TYPE[respCnf.item.layout_key];
			let list = [];
			switch (state.progressType ){
				case state.PROGRESS_TYPE_STEPS:
				case state.PROGRESS_TYPE_SLIDER:
					respCnf.details.select.options.forEach(lvl => {
						if(lvl.inside.options.length){
							lvl.inside.options.forEach(division => {
								list.push({
									key: `${lvl.key}_${division.key}`,
									lvlKey: lvl.key,
									divisionKey: division.key,
									img: division.additional.image,
									lvlText: lvl.text,
									divisionText: division.text
								})
							})

						} else {
							list.push({
								key: lvl.key,
								lvlKey: lvl.key,
								divisionKey: null,
								img: lvl.additional.image,
								lvlText: lvl.text
							})
						}

					});

					state.serviceRanges = list;

					if(state.progressType === state.PROGRESS_TYPE_SLIDER){
						state.serviceProgressCnf = {
							labelText: respCnf.item.translations.counter_title
						};
					}

					break;
				case state.PROGRESS_TYPE_SINGLE:

					state.serviceProgressCnf = {
						labelText: respCnf.item.translations.counter_title
					};

					break;
				case state.PROGRESS_TYPE_RANGES:
					state.serviceProgressCnf = {
						labelText: respCnf.item.translations.point_title
					};
					break;
			}
		},

		SET_LOADING_STATUS(state, {value_key, status}){
			state[value_key] = status;
		},

		RESET_STORE(state){
			state.serviceRanges = [];
			state.serviceProgressCnf = {};
			state.progressType = null;
		}
	},

	actions:{
		GET_SERVICE_RANGES: async({state, rootGetters, getters, commit}, serviceId) => {
			try {
				commit('SET_LOADING_STATUS', { value_key: 'loadingGetServiceRanges', status: true });

				let resp = await $axios.get(`/api/services/${serviceId}`);

				commit('BUILD_SERVICE_RANGES', resp);

				return resp;
			} catch (e) { throw e; } finally {
				commit('SET_LOADING_STATUS', { value_key: 'loadingGetServiceRanges', status: false });
			}
		},
	}
}
