//utils
import './utils/filters';
import './utils/global-components';
import './utils/icons';
import './utils/fontawesome';

import './utils/fn';
Vue.prototype.$fn = $fn;

//plugins
import '@/utils/axios';

//
import Vue from './utils/vue_base.js';
import App from './App.vue';
Vue.config.productionTip = false;

import store from './store';
import router from './router';


//--------bus events----------------/
Vue.prototype.$bus = new Vue();


//--------noty--------------------------/
import VueNoty from 'vuejs-noty'
import 'vuejs-noty/dist/vuejs-noty.css'
Vue.use(VueNoty, {
  timeout: 4000,
  theme: 'sunset',
  progressBar: true,
  layout: 'bottomRight'
});


//--------validator---------------------/
import VeeValidate, { Validator } from 'vee-validate';
Vue.use(VeeValidate);


//--------mask-------------------/
import { VueMaskDirective } from 'v-mask'
Vue.directive('mask', VueMaskDirective);


//--------modal--------------------------/
import VModal from 'vue-js-modal';
Vue.use(VModal, {
  dynamic: true,
  injectModalsContainer: true
});

window.$MODAL_OPTIONS = {
  default: {
	delay: 100,
	draggable: false,
	adaptive: true,
	scrollable: true,
	height: 'auto',
	clickToClose: true,
  }
};
$MODAL_OPTIONS.big = {
  ...$MODAL_OPTIONS.default,
  width: 900,
  maxWidth: 900
};
$MODAL_OPTIONS.full = {
  ...$MODAL_OPTIONS.default,
  width: 1280,
  maxWidth: 1280,
};


//--------------------------------------/
const app = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');

window.$VUE_APP = app;

