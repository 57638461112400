import {findIndex} from 'lodash';
import Vue from 'vue';

export default {
	namespaced: true,
	state:{
		list: [],
		listPagination: {},
		listFilter: {
			page: null,
			limit: null,
			search: null,
		},
		loadingGetList: false,

		service: {
			details: {
				champions: [],
				order_params: [],
				params: [],
				regions: [],
				select: {
					options: [],
					additional: {}
				}
			},
			item: {
				id: null,
				key: '',
				layout_key: '',
				is_active: 0,
				game: {},
				translations: {
					title: {
						ru: '',
						en: ''
					}
				}
			}
		},

		CONSTRUCTOR_TYPES: {
			'lvl_dv': ['level', 'division'],
			'range': ['range'],
			'lvl_slider': ['level', 'slider'],
			'lvl_dv_slider': ['level', 'division', 'slider'],
			'lvl': ['level'],
			'slider': ['slider_single'],
			'lvl_single': ['level_single']
		}
	},

	getters:{
		listActiveFilter(state){
			let filter = {};

			$fn.forIn(state.listFilter, (val, key) => {
				if (val !== null && val !== '' ) filter[key] = val;
			});
			return filter
		},

		servicesMapById(state, getters, rootState){
			let map = {};
			state.list.forEach(el => {
				map[el.id] = `${el.game.title} - ${el.translations.title ? el.translations.title[rootState.selectedLang] : 'NO_TRANSLATE'}`
			});
			return map;
		}
	},

	mutations:{
		SET_LIST(state, list){
			state.list = list;
		},

		SET_PAGINATION(state, pagination){
			state.listPagination = pagination;
		},

		SET_SERVICE(state, service){
			state.service.item = service.item;
			state.service.details = service.details;
		},

		CREATE_BASE_SEEVICE(state, game){
			state.service.item.game = game
		},

		RESET_STORE(state){
			state.service = {
				details: {
					champions: [],
					order_params: [],
					params: [],
					regions: [],
					select: {
						options: [],
						additional: {}
					}
				},
				item: {
					id: null,
					key: '',
					layout_key: '',
					is_active: 0,
					game: {},
					translations: {
						title: {
							ru: '',
							en: ''
						}
					}
				}
			}
		},

		RESET_STORE_SELECT(state, key){
			state.service.details.select = {
				options: [],
				additional: {}
			}
		},

		SET_TRANSLATIONS_PROP(state, key){
			Vue.set(state.service.item.translations, key, {
				ru: '',
				en: ''
			});
		},

		SET_SERVICE_TITLE(state, {title, lang}){
			state.service.item.translations.title[lang] = title
		},
		SET_SERVICE_KEY(state, key){
			state.service.item.key = key
		},
		SET_SERVICE_TRANSLATE(state, {key, title, lang}){
			state.service.item.translations[key][lang] = title
		},

		SET_SERVICE_ACTIVE(state, status){
			state.service.item.is_active = +status
		},

		SET_CONSTRUCTOR_TYPE(state, type){
			state.service.item.layout_key = type;
		},

		SAVE_ORDER_OPTION(state, option){
			let params = state.service.details.order_params,
				index = findIndex(params, ['key', option.key]);

			if (index !== -1){
				Vue.set(params, index, option)
			} else {
				params.push(option);
			}
		},
		DELETE_ORDER_OPTION(state, option){
			let params = state.service.details.order_params,
				index = findIndex(params, ['key', option.key]);

			Vue.delete(params, index);
		},

		SAVE_CHAMPION(state, option){
			let params = state.service.details.champions,
				index = findIndex(params, ['key', option.key]);

			if (option.is_selected){
				params.forEach(item => {
					item.is_selected = false;
				})
			}

			if (index !== -1){
				Vue.set(params, index, option)
			} else {
				params.push(option);
			}
		},

		DELETE_CHAMPION(state, option){
			let params = state.service.details.champions,
				index = findIndex(params, ['key', option.key]);

			Vue.delete(params, index);
		},

		SAVE_SERVICE_OPTION(state, option){
			let params = state.service.details.params,
				index = findIndex(params, ['key', option.key]);

			if (index !== -1){
				Vue.set(params, index, option)
			} else {
				params.push(option);
			}
		},
		DELETE_SERVICE_OPTION(state, option){
			let params = state.service.details.params,
				index = findIndex(params, ['key', option.key]);

			Vue.delete(params, index);
		},

		SAVE_REGION(state, region){
			let params = state.service.details.regions,
				index = findIndex(params, ['key', region.key]);

			if (region.is_default){
				params.forEach(item => {
					item.is_default = false;
				})
			}

			if (index !== -1){
				Vue.set(params, index, region)
			} else {
				params.push(region);
			}
		},
		DELETE_REGION(state, region){
			let params = state.service.details.regions,
				index = findIndex(params, ['key', region.key]);

			Vue.delete(params, index);
		},

		//data
		SAVE_DATA_LVL(state, lvl){
			if (!state.service.details.select){
				state.service.details.select = {};

				Vue.set(state.service.details.select, 'options', []);
				Vue.set(state.service.details.select, 'additional', {});
			}

			let params = state.service.details.select.options,
				index = findIndex(params, ['key', lvl.key]);

			if (lvl.additional.is_current_default){
				params.forEach(option => {
					option.additional.is_current_default = false;

					if(option.key !== lvl.key){
						option.inside.options.forEach(item => {
							item.additional.is_current_default = false;
						})
					}
				});
			}

			if (lvl.additional.is_desired_default){
				params.forEach(option => {
					option.additional.is_desired_default = false;

					if(option.key !== lvl.key){
						option.inside.options.forEach(item => {
							item.additional.is_desired_default = false;
						})
					}
				});
			}

			if (index !== -1){
				Vue.set(params, index, lvl)
			} else {
				params.push(lvl);
			}
		},

		SAVE_DATA_RANGE(state, range){
			let params = state.service.details.select.options,
				index = findIndex(params, ['key', range.key]);

			if (index !== -1){
				Vue.set(params, index, range)
			} else {
				params.push(range);
			}
		},

		SAVE_DATA_COUNTER(state, counter){
			if (!state.service.details.select){
				state.service.details.select = {};
				Vue.set(state.service.details.select, 'additional', {});
			}

			state.service.details.select.additional = {
				counter_min: counter.counter_min,
				counter_max: counter.counter_max,
				counter_selected: counter.counter_selected,
				price: counter.price,
				time: counter.time,
				limit: counter.limit,
				selected_min: counter.selected_min,
				selected_max: counter.selected_max
			}
		},

		DELETE_DATA_LVL(state, lvl){
			let params = state.service.details.select.options,
				index = findIndex(params, ['key', lvl.key]);

			Vue.delete(params, index);
		},

		SET_LOADING_STATUS(state, {value_key, status}){
			state[value_key] = status;
		}
	},

	actions:{
		GET_LIST: async({state, rootGetters, getters, commit}) => {
			try {
				commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

				if(state.list.length === 1 && getters.listActiveFilter.page > 1){
					state.listFilter.page = state.listFilter.page -1;
				}

				let resp = await $axios.get(`/api/services`, {
					params: getters.listActiveFilter

				});

				commit('SET_LIST', resp.list);
				commit('SET_PAGINATION', resp.pagination);

				return resp;
			} catch (e) { throw e; } finally {
				commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
			}
		},

		GET_SERVICE({commit}, id){
			return $axios.get(`/api/services/${id}`).then(resp => {
				commit('SET_SERVICE', resp);
			});
		},

		GET_GAME({commit}, id){
			return $axios.get(`/api/games/${id}`).then(resp => {
				commit('CREATE_BASE_SEEVICE', resp.item);
			})
		},

		SAVE_SERVICE({state, commit}){
			return $axios.post(`/api/services`, {
				service: state.service
			})
		},

		DELETE_SERVICE({state}){
			return $axios.delete(`/api/services/${state.service.item.id}`);
		}
	}
}


