export default {
	namespaced: true,
	state: {
		ROLE_BOOSTER: 'booster',
		ROLE_ADMIN: 'admin',
		ROLE_ROOT: 'root',
		ROLE_CLIENT: 'client',

		list: [],
		listPagination: {},

		listFilter: {
			page: 1,
			limit: 15,
		},

		loadingGetList: false,
		loadingSaveItem: false,
	},

	getters: {
		listActiveFilter(state){
			let filter = {};

			$fn.forIn(state.listFilter, (val, key) => {
				if (val !== null && val !== '' ) filter[key] = val;
			});
			return filter
		}
	},

	mutations: {
		SET_LIST(state, list){
			state.list = list.reverse();
		},
		SET_PAGINATION(state, pagination){
			state.listPagination = pagination;
		},
		SET_FILTER_LIST(state, {key, val}){
			state.listFilter[key] = val
		},
		SET_LOADING_STATUS(state, {value_key, status}){
			state[value_key] = status;
		}
	},

	actions:{
		GET_LIST: async({state, rootGetters, getters, commit}, orderId) => {
			try {
				commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

				let resp = await $axios.get(`/api/orders/${orderId}/messages`, {
					params: getters.listActiveFilter
				});

				commit('SET_LIST', resp.list);
				commit('SET_PAGINATION', resp.pagination);

				return resp;
			} catch (e) { throw e; } finally {
				commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
			}
		},

		SAVE_ITEM: async({state, rootGetters, commit, dispatch }, {orderId, formData}) => {
			try {
				commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

				let resp = await $axios.post(`/api/orders/${orderId}/messages`, formData);

				dispatch('GET_LIST', orderId);

				return resp;
			} catch (e) { throw e; } finally {
				commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
			}
		},
	}
}
