<template>
	<div class="dash">
		<div class="container">
			<div class="dash__title">
				Welcome in work cabinet
			</div>
		</div>

		<div class="dash__orders">
			<orders-index :inDashboard="true"></orders-index>
		</div>
	</div>
</template>

<script>
	import {mapState, mapGetters, mapMutations, mapActions, } from 'vuex';

	import OrdersIndex from '@views/orders/OrdersIndex';

	export default {
		name: 'dash-common-index',
		components:{
			OrdersIndex
		},

		created(){
			this.SET_FILTER_LIST({key: 'statuses', val: ['created', 'active', 'paid', 'paused']});
		},

		methods:{
			...mapMutations({
				SET_FILTER_LIST: 'orders/SET_FILTER_LIST'
			}),
		}
	};
</script>

<style scoped lang=scss>
	.dash{
		padding: 100px 0 20px;
		&__title{
			text-align: center;
			font-size: 28px;
			margin-bottom: 20px;
			font-family: $f_alt;
		}
	}
</style>
