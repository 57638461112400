<template>
	<div class="header">
		<div class="header__main">
			<button class="btn btn--icon" @click="hasOpenMenu = !hasOpenMenu">
				<font-awesome-icon v-if="hasOpenMenu" icon="fa-regular fa-ellipsis-vertical" />
				<font-awesome-icon v-else icon="fa-regular fa-bars" />
			</button>
		</div>

		<div class="header__options">
			<!--<div class="header__lang">-->
				<!--<dropdown-menu icon="globe-americas">-->
					<!--<div class="list list&#45;&#45;lang">-->
						<!--&lt;!&ndash;@TODO fixed has-disabled&ndash;&gt;-->
						<!--<li class="list__item" v-for="lang in languages"-->
								<!--:key="lang.code"-->
								<!--:class="{'has-active': lang.code === $i18n.locale, 'has-disabled': lang.code !== 'ru'}"-->
						<!--@click="onSetLang(lang.code)">-->
							<!--<span>{{lang.code}}</span>-->
						<!--</li>-->
					<!--</div>-->
				<!--</dropdown-menu>-->
			<!--</div>-->

			<!--<div class="header__option has-disabled">-->
				<!--<dropdown-menu icon="bell">-->
					<!--<ul class="list">-->
						<!--<li class="list__item">Lorem ipsum dolor sit amet.</li>-->
						<!--<li class="list__item">Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.</li>-->
						<!--<li class="list__item">Lorem ipsum dolor sit amet.</li>-->
						<!--<li class="list__item">Lorem ipsum dolor sit amet.</li>-->
					<!--</ul>-->
				<!--</dropdown-menu>-->
			<!--</div>-->
			<!--<div class="header__option" v-if="hasUser">-->
				<!--<router-link :to="`/employees/${user.id}`" class="btn ripple clRipple btn&#45;&#45;icon">-->
					<!--<v-icon name="user"></v-icon>-->
				<!--</router-link>-->
			<!--</div>-->

			<div class="header__option">
				<button class="btn ripple clRipple btn--icon" @click="onLogout">
					<font-awesome-icon icon="fa-regular fa-arrow-right-from-bracket"/>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';
	import DropdownMenu from '@components/DropdownMenu';

	export default {
		name: 'header-top',
		components: {
			DropdownMenu, 
		},

		computed: {
			...mapState({
				user: state => state.auth.user,
			}),

			hasOpenMenu: {
				get () {
					return this.$store.state.hasOpenMenu;
				},
				set (status) {
					this.SET_OPEN_MENU(status);
				}
			},

		},

		methods: {

			...mapMutations({
				SET_OPEN_MENU: 'SET_OPEN_MENU',
			}),

			...mapActions({
				AUTH_LOGOUT: 'AUTH_LOGOUT'
			}),

			onLogout () {
				this.AUTH_LOGOUT().then(() => {
					this.$router.push({name: 'login'});
				});
			},

			onSetLang (locale) {
				this.$i18n.setLang(locale).then(() => {
					this.$bus.$emit('dropdown-hide');
					window.location.reload();
				});
			},

		}
	};
</script>

<style scoped lang=scss>
	.header {
		padding: 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 20px;
		 box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
		background: #fff;
		height: 60px;
		&__options {
			display: flex;
			align-items: center;
		}
		&__main {
			display: flex;
			align-items: center;
		}
	}

	.list {
		width: 250px;
		&__item {
			font-size: 12px;
			padding: 10px 10px;
			transition: all 0.1s;
			display: flex;
			align-items: center;
			border-radius: 5px;
			cursor: pointer;
			&.has-active {
				background: $c_alt_light;
				pointer-events: none;
			}
			&:hover {
				background: $c_alt;
				color: #f0f0f0;
				/* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); */
			}
		}

		&--lang {
			width: 43px;
			.list__item {
				text-transform: uppercase;
				justify-content: center;
			}
		}
	}

	.menu {
		padding-right: 10px;
		margin-right: 10px;
		position: relative;
		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 9px;
			right: 0;
			width: 1px;
			background: $c_border;
			bottom: 9px;
		}
		&__list {
			display: flex;
			align-items: center;
		}
		&__link {
			padding: 5px;
			line-height: 0;
			display: flex;
		}
	}
	.has-mobile{
		@media (max-width: $md) {
			display: none;
		}
	}
</style>
