import Vue from 'vue';

export default {
    state: {
	    ROLE_ROOT: 'root',
	    ROLE_ADMIN: 'admin',
		ROLE_BOOSTER: 'booster',

        token: localStorage.getItem('_token_boost_admin') || null,
	    user: JSON.parse(localStorage.getItem('_user_boost_admin')) || null,
		permissions: JSON.parse(localStorage.getItem('_permissions_boost_admin')) || null,

	  	hasLoadingLogin: false,
		hasLoadingPermissions: false
    },

    getters: {
        hasLogged: state => {
            return state.token !== null;
        },

	    hasUser(state){
        	return !!state.user
	    },

		hasAdminTypeTypography(state){
			return !!state.user &&  !!state.user.typography && !!state.user.typography.id
		},
		currentTypography(state, getters){
        	if (!getters.hasAdminTypeTypography) return null;
        	return state.user.typography
		},

		accessRoot(state){
			if (!state.user) return false;
			return state.user.role === state.ROLE_ROOT
		},

	    accessAdmin(state){
		    if (!state.user) return false;
		    return state.user.role === state.ROLE_ADMIN
	    },

		accessBooster(state){
			if (!state.user) return false;
			return state.user.role === state.ROLE_BOOSTER
		}
    },

    mutations: {
        SET_TOKEN (state, token) {
            state.token = token;
        },

	    SET_USER (state, user) {
		    state.user = user;
	    },

		SET_PERMISSIONS (state, permissions) {
			state.permissions = permissions;
			//set global permissions
			window.$permissions = permissions;
		},

		SET_STATUS_PERMISSIONS(state, status){
        	state.hasLoadingPermissions = status
		},

        LOGOUT(state){
            state.token = null;
            state.user = null;
            state.permissions = null;
        },

		SET_LOADING_STATUS(state, {value_key, status}){
			state[value_key] = status;
		}
    },

    actions: {
		AUTH_LOGIN: async({rootState, rootGetters, state, getters, commit}, formData) => {
			try {
				commit('SET_LOADING_STATUS', { value_key: 'hasLoadingLogin', status: true });

				let resp = await $axios.post('/api/staff/auth/login', formData);

				let token = 'Bearer ' + resp.token;

				localStorage.setItem('_token_boost_admin', token);
				$axios.defaults.headers.common['Authorization'] = token;
				commit('SET_TOKEN', token);


				let user = resp.staff;
				localStorage.setItem('_user_boost_admin', JSON.stringify(user));
				commit('SET_USER', user);

				state.hasLoadingLogin = false;

				return resp;
			} catch (e) { throw e; } finally {
				commit('SET_LOADING_STATUS', { value_key: 'hasLoadingLogin', status: false });
			}
		},

        AUTH_LOGOUT({commit}){
            return new Promise((resolve, reject) => {
                commit('LOGOUT');

                localStorage.removeItem('_token_boost_admin');
                localStorage.removeItem('_user_boost_admin');
                localStorage.removeItem('_permissions_boost_admin');
                delete $axios.defaults.headers.common['Authorization'];

                resolve();
            })
        }
    }
};
