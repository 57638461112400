import { render, staticRenderFns } from "./FormColorPicker.vue?vue&type=template&id=8f47ea20&scoped=true&"
import script from "./FormColorPicker.vue?vue&type=script&lang=js&"
export * from "./FormColorPicker.vue?vue&type=script&lang=js&"
import style0 from "./FormColorPicker.vue?vue&type=style&index=0&id=8f47ea20&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8f47ea20",
  null
  
)

export default component.exports