<template>
	<div class="card" :class="{'has-created': order.status === ORDER_STATUS_CREATED,
	                            'has-paid': order.status === ORDER_STATUS_PAID,
							    'has-active': order.status === ORDER_STATUS_ACTIVE,
							    'has-faild': order.status === ORDER_STATUS_FAILED,
							    'has-ended': order.status === ORDER_STATUS_ENDED,
							    'has-canceled': order.status === ORDER_STATUS_CANCELED,
							    'has-active': accessBooster}">

		<div class="card__td card__td--game">
			<div class="card__logo">
				<img :src="order.game.icon" alt="">
			</div>
		</div>
		<div class="card__td card__td--service">
			<!--<div class="card__service">Silver1 - Gold 1</div>-->
			<div class="card__title">{{order.service.translations.title['en']}}</div>
			<div class="card__client">{{order.client ? order.client.email : order.email}}</div>
			<router-link :to="`orders/${order.id}`" class="card__view">
				<v-icon name="eye"></v-icon>
				<span>Show details</span>
			</router-link>
		</div>
		<div class="card__td card__td--id">
			<div class="card__id">{{ORDER_PRE_KEY}}{{order.id}}</div>
			<div class="card__deadline">Deadline: {{order.deadline_at | dateNormalize}}</div>

			<div class="card__booster">
				<div class="card__boost" v-if="order.booster">
					<font-awesome-icon icon="fa-solid fa-user"/>
					<span>{{order.booster.name}}</span>
					<div class="card__edit" @click="onAddBoosterToOrder" v-if="!accessBooster">
						<font-awesome-icon icon="fa-regular fa-pen-to-square"/>
					</div>
				</div>

				<div class="link" v-else-if="!accessBooster" @click="onAddBoosterToOrder">
					<font-awesome-icon icon="fa-regular fa-plus-circle"/>
					<span>Selected booster</span>
				</div>
			</div>

		</div>
		<div class="card__td card__td--options">
			<!--<div class="card__options">-->
				<!--<div class="card__option">options 1</div>-->
				<!--<div class="card__option">options 2</div>-->
				<!--<div class="card__option">options 3</div>-->
				<!--<div class="card__option">options 4</div>-->
				<!--<div class="card__option">options 5</div>-->
				<!--<div class="card__option">options 6</div>-->
			<!--</div>-->
		</div>
		<div class="card__td card__td--price">
			<div class="card__price">{{order.price}}$</div>
			<!--<div class="card__infPrice">[{{order.price}} * 80%]</div>-->
		</div>
		<div class="card__td card__td--nav">
			<div class="card__navs">
				<div class="card__nav" v-if="!accessBooster">
					<form-select v-model="status"
							class="has-sm _m-0"
							:options="formattedStatuses"
							name="status"
							option_label="title"
							@input="onChangeStatus"
							label="Status"></form-select>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState, mapGetters, mapMutations, mapActions, } from 'vuex';

	import ModalAddBooster from '@views/orders/components/ModalAddBooster';

	export default {
		name: 'order-card',
		props: ['order', 'active'],
		data(){
			return {
				status: null
			}
		},

		computed:{
			...mapState({
				ORDER_PRE_KEY: state => state.orders.ORDER_PRE_KEY,
				ORDER_STATUS_CREATED: state => state.orders.ORDER_STATUS_CREATED,
				ORDER_STATUS_PAID: state => state.orders.ORDER_STATUS_PAID,
				ORDER_STATUS_ACTIVE: state => state.orders.ORDER_STATUS_ACTIVE,
				ORDER_STATUS_ENDED: state => state.orders.ORDER_STATUS_ENDED,
				ORDER_STATUS_FAILED: state => state.orders.ORDER_STATUS_FAILED,
				ORDER_STATUS_CANCELED: state => state.orders.ORDER_STATUS_CANCELED,

				statuses: state => state.orders.statuses,
			}),
			...mapGetters({
				accessBooster: 'accessBooster'
			}),

			formattedStatuses(){
				return 	$fn.map(this.statuses, (status, key) => {
					return{
						id: key,
						title: status
					}
				})
			},
		},

		created(){
			this.status = $fn.find(this.formattedStatuses, ['id', this.order.status])
		},

		methods:{
			...mapActions({
				SAVE_ITEM: 'orders/SAVE_ITEM'
			}),

			onChangeStatus(){
				if(!this.status) return;

				this.SAVE_ITEM({
					id: this.order.id,
					status: this.status.id,
					booster_id: this.order.booster ? this.order.booster.id : null
				})
			},

			onAddBoosterToOrder(){
				this.$modal.show(ModalAddBooster,{
					order: this.order
				}, $MODAL_OPTIONS.default);
			},
		}
	};
</script>

<style scoped lang=scss>
	.card{
		display: flex;
		background: #DCD2E4;
		border-radius: 5px;
		&.has-created{
			background: #0cff008f;
		}
		&.has-paid{
			background: orange;
		}
		&.has-active{
			background: #0080003d;
			border:2px solid $c_success;
			box-shadow: 0 1px 8px $c_success;
		}
		&.has-canceled{
			background: #DCD2E4;
		}
		&.has-faild{
			background: #ca3e4780;
		}
		&.has-ended{
			background: #fff;
			border:2px solid #333;
		}
		&__td{
			padding: 5px;
			&--game{
				width: 10%;
			}
			&--service{
				width: 30%;
			}
			&--id{
				width: 10%;
			}
			&--options{
				width: 15%;
			}
			&--price{
				width: 10%;
			}
			&--nav{
				width: 25%;
			}
		}
		&__booster{
			.link{
				span{
					white-space: nowrap;
				}
			}
		}
		&__logo{
			width: 60px;
		}
		&__id{
			font-family: $f_alt;
			font-size: 16px;
		}
		&__service,
		&__price{
			font-family: $f_alt;
			font-size: 20px;
			font-weight: 500;
		}
		&__title{
			font-family: $f_alt;
			font-size: 16px;
		}

		&__deadline{
			font-family: $f_alt;
			white-space: nowrap;
		}
		&__client{
			font-size: 14px;
		}
		&__infPrice{
			color: $c_text_light;
			white-space: nowrap;
			font-size: 12px;
			font-weight: bold;
		}
		&__options{
			display: flex;
			flex-wrap: wrap;
		}
		&__option{
			padding: 5px 10px;
			background: $c_success;
			margin-right: 5px;
			margin-bottom: 5px;
			font-family: $f_alt;
			color: #fff;
			font-size: 12px;
			line-height: 12px;
			border-radius: 5px;
		}
		&__view{
			display: flex;
			align-items: center;
			&:hover{
				svg{
					fill: darkblue;
				}
				span{
					color: darkblue;
					text-decoration: none;
				}
			}
			svg{
				margin-right: 5px;
			}
			span{
				text-decoration: underline;
			}
		}
		&__boost{
			font-family: $f_alt;
			display: flex;
			align-items: center;
			svg{
				margin-right: 5px;
			}
		}
		&__edit{
			color: $c_text_light;
			margin-left: 5px;
			cursor: pointer;
			display: block;
			position: relative;
			z-index: 1;
			&:hover{
				color: $c_alt;
			}
			svg{
				width: 15px;
				height: 15px;
			}
		}
	}
</style>
