export default {
	namespaced: true,
	state:{
		games: [],
		game: {}
	},

	getters:{

	},

	mutations:{
		SET_GAMES(state, games){
			state.games = games;
		},

		SET_GAME(state, game){
			state.game = game;
		}
	},

	actions:{
		GET_GAMES({commit}){
			return $axios.get(`/api/games`).then(resp => {
				commit('SET_GAMES', resp.list);
			})
		},

		SAVE_GAME({commit}, payload){
			return $axios.post(`/api/games`, payload)
		},

		CHANGE_POSITION({}, list){
			return $axios.post(`/api/games/sort`, {
				list: list
			})
		},


		DELETE_GAME({commit}, id){
			return $axios.delete(`/api/games/${id}`);
		},

		GET_GAME({commit}, id){
			return $axios.get(`/api/games/${id}`).then(resp => {
				commit('SET_GAME', resp);
			})
		},

		CHANGE_SERVICE_POSITION({}, list){
			return $axios.post(`/api/services/sort`, {
				list: list
			})
		},
	}
}


