<template>
	<div class="dash">
		<div class="container">
			<div class="dash__title">
				Welcome in booster cabinet
			</div>

			<div class="dash__orders">
				<orders-index :inDashboard="true"></orders-index>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState, mapGetters, mapMutations, mapActions, } from 'vuex';

	import OrdersIndex from '@views/orders/OrdersIndex';

	export default {
		name: 'dashboard-booster-index',
		components:{
			OrdersIndex
		},

		created(){
			console.log();
			this.SET_FILTER_LIST({key: 'statuses', val: ['created', 'active', 'paid', 'paused']});
		},

		methods:{
			...mapMutations({
				SET_FILTER_LIST: 'orders/SET_FILTER_LIST'
			}),
		}
	};
</script>

<style scoped lang=scss>
	.dash{
		padding: 100px 0 20px;
		&__title{
			text-align: center;
			font-size: 28px;
			margin-bottom: 20px;
			font-family: $f_alt;
			span{
				color: $c_text_light;
			}
		}
	}
</style>
