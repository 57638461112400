<template>
	<div class="dashboard">
		<dashboard-booster-index v-if="accessBooster"></dashboard-booster-index>
		<dashboard-common-index v-else></dashboard-common-index>
	</div>
</template>

<script>
	import {mapState, mapGetters, mapMutations, mapActions, } from 'vuex';

	import DashboardBoosterIndex from './modules/DashboardBoosterIndex';
	import DashboardCommonIndex from './modules/DashboardCommonIndex';

	export default {
		name: 'dashboard-index',
		components:{
			DashboardBoosterIndex,
			DashboardCommonIndex
		},
		computed:{
			...mapGetters({
				accessBooster: 'accessBooster',
			}),
		}
	};
</script>

<style scoped lang="scss">

</style>
