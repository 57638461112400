import chat from './chat.js';
import progress from './progress.js';

export default {
	namespaced: true,
	modules: {
		chat,
		progress,
	},
	state: {
		ORDER_PRE_KEY: '#MY32R',

		ORDER_STATUS_ACTIVE: 'active',
		ORDER_STATUS_CANCELED: 'canceled',
		ORDER_STATUS_CREATED: 'created',
		ORDER_STATUS_ENDED: 'ended',
		ORDER_STATUS_FAILED: 'failed',
		ORDER_STATUS_PAID: 'paid',
		ORDER_STATUS_PAUSED: 'paused',

		list: [],
		item: {},
		profits: [],

		statuses: [],
		clients: [],
		boosters: [],

		listPagination: {},
		listFilter: {
			page: 1,
			limit: 15,
			statuses: ['created', 'active', 'paid', 'paused'],
			client_id: null,
			booster_id: null,
			order_by: 'created_at',
			order_dir: 'desc'
		},

		loadingGetList: false,
		loadingGetStatusesList: false,
		loadingGetItem: false,
		loadingSaveItem: false,
		loadingGetClients: false,
	},

	getters: {
		listActiveFilter(state){
			let filter = {};

			$fn.forIn(state.listFilter, (val, key) => {
				if (val !== null && val !== '' ) filter[key] = val;
			});
			return filter
		}
	},

	mutations:{
		SET_STATUSES_LIST(state, list){
			state.statuses = list;
		},
		SET_CLIENTS_LIST(state, list){
			state.clients = list;
		},
		SET_BOOSTERS_LIST(state, list){
			state.boosters = list;
		},
		SET_LIST(state, list){
			state.list = list;
		},
		SET_ITEM(state, item){
			state.item = item;
		},
		SET_ITEM_PROFIT(state, profits){
			state.profits = profits;
		},
		SET_PAGINATION(state, pagination){
			state.listPagination = pagination;
		},
		SET_FILTER_LIST(state, {key, val}){
			state.listFilter[key] = val
		},

		SET_LOADING_STATUS(state, {value_key, status}){
			state[value_key] = status;
		}
	},

	actions: {
		GET_LIST: async({state, rootGetters, getters, commit}, payload = {filters: {}}) => {
			try {
				commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

				if(state.list.length === 1 && getters.listActiveFilter.page > 1){
					state.listFilter.page = state.listFilter.page -1;
				}
				console.log('payload.filter', payload, payload.filters);
				let resp = await $axios.get(`/api/orders`, {
					params: $fn.assign(getters.listActiveFilter, payload.filters)

				});

				commit('SET_LIST', resp.list);
				commit('SET_PAGINATION', resp.pagination);

				return resp;
			} catch (e) { throw e; } finally {
				commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
			}
		},

		GET_ITEM: async({state, getters, rootGetters, commit}, id) => {
			try {
				commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: true });

				let resp = await $axios.get(`/api/orders/${id}`);

				commit('SET_ITEM', resp.single);
				commit('SET_ITEM_PROFIT', resp.profits);

				return resp;
			} catch (e) { throw e; } finally {
				commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: false });
			}
		},

		SAVE_ITEM: async({state, rootGetters, commit, dispatch }, formData) => {
			try {
				commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

				let resp = await $axios.post(`/api/orders`, formData);

				dispatch('GET_LIST');

				return resp;
			} catch (e) { throw e; } finally {
				commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
			}
		},

		ADD_BOOSTER_ORDER: async({state, rootGetters, commit, dispatch }, formData) => {
			try {
				commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

				let resp = await $axios.post(`/api/orders`, formData);

				dispatch('GET_LIST');
				dispatch('GET_ITEM', formData.id);

				return resp;
			} catch (e) { throw e; } finally {
				commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
			}
		},

		GET_STATUSES: async({state, rootGetters, getters, commit}) => {
			try {
				commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

				let resp = await $axios.get(`/api/orders/statuses`);

				commit('SET_STATUSES_LIST', resp.list);

				return resp;
			} catch (e) { throw e; } finally {
				commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
			}
		},

		GET_CLIENTS: async({state, rootGetters, getters, commit}) => {
			try {
				commit('SET_LOADING_STATUS', { value_key: 'loadingGetClients', status: true });

				let resp = await $axios.get(`/api/clients`, {
					params: {limit: 9999}
				});

				commit('SET_CLIENTS_LIST', resp.list);

				return resp;
			} catch (e) { throw e; } finally {
				commit('SET_LOADING_STATUS', { value_key: 'loadingGetClients', status: false });
			}
		},

		GET_BOOSTERS: async({state, rootGetters, getters, commit}) => {
			try {
				commit('SET_LOADING_STATUS', { value_key: 'loadingGetClients', status: true });

				let resp = await $axios.get(`/api/staff/boosters`, {
					params: {limit: 9999}
				});

				commit('SET_BOOSTERS_LIST', resp.data);

				return resp;
			} catch (e) { throw e; } finally {
				commit('SET_LOADING_STATUS', { value_key: 'loadingGetClients', status: false });
			}
		},

		GET_ORDER_PROFIT: async({state, rootGetters, getters, commit}, orderId) => {
			try {
				commit('SET_LOADING_STATUS', { value_key: 'loadingGetClients', status: true });

				let resp = await $axios.get(`/api/profits`, {
					params: {order_ids: [orderId]}
				});

				return resp;
			} catch (e) { throw e; } finally {
				commit('SET_LOADING_STATUS', { value_key: 'loadingGetClients', status: false });
			}
		},
	}

};
