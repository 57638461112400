<template>
	<div class="time">
		<div class="time__item">
			<vue-timepicker v-model="ranges.startTime" close-on-complete
					placeholder="Начало"
					@change="onChange"></vue-timepicker>
		</div>

		<span class="time__to"> до </span>

		<div class="time__item">
			<vue-timepicker v-model="ranges.endTime" close-on-complete
					placeholder="Конец"
					@change="onChange"></vue-timepicker>
		</div>
	</div>
</template>

<script>
	import VueTimepicker from 'vue2-timepicker'

	export default {
		name: 'form-time-range',
		props: ['value'],
		components:{
			VueTimepicker
		},

		data() {
			return {
				ranges: {
					startTime: {
						"HH": "08",
						"mm": "00"
					},
					endTime: {
						"HH": "09",
						"mm": "00"
					}
				},
			}
		},

		created(){
			if (!this.value) {
				this.ranges.startTime = null;
				this.ranges.endTime = null;
				return;
			}

			let arr = this.value.split('-');
			let start = arr[0];
			let end = arr[1];

			this.ranges.startTime = {
				HH: start.split(':')[0],
				mm: start.split(':')[1]
			};
			this.ranges.endTime = {
				HH: end.split(':')[0],
				mm: end.split(':')[1]
			};

		},
		
		methods:{
			onChange(data){
				let start = this.ranges.startTime || {};
				let end = this.ranges.endTime || {};

				if(!start || !end || !start.HH || !start.mm || !end.HH || !end.mm) {
					this.$emit('change', null);
				} else {
					let time = `${start.HH}:${start.mm}-${end.HH}:${end.mm}`;
					console.log('time', time);
					this.$emit('change', time)
				}
			}
		}
	};
</script>

<style scoped lang=scss>
	@import '~vue2-timepicker/dist/VueTimepicker.css';

	.time{
		display: flex;
		align-items: center;
		&__to{
			padding: 0 5px;
		}
	}
</style>
