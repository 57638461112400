<template>
	<div class="login">
		<div class="form">
			<div class="form__head">
				<div class="form__title">DARKBOOSTING CABINET</div>
			</div>

			<div class="form__item">
				<form-input v-model="formData.name"
						label="Login"
						rules="required"
						@enter="onLogin"
						name="name"></form-input>
			</div>

			<div class="form__item">
				<form-input v-model="formData.password"
						type="password"
						label="Password"
						rules="required"
						@enter="onLogin"
						name="password"></form-input>
			</div>

			<div class="form__footer">
				<button class="btn" :class="{'has-loading': hasLoadingLogin}" @click="onLogin">
					<font-awesome-icon icon="fa-solid fa-user"/>
					<span>Login</span>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
    import {mapState, mapGetters, mapMutations, mapActions, } from 'vuex';

	export default {
		name: 'login-index',
		data () {
			return {
				formData:{
					name: null,
					password: null,
				}
			};
		},

	    computed: {
		    ...mapState({
			    KEY_LAYOUT_LOGIN: state => state.KEY_LAYOUT_LOGIN,
			    hasLoadingLogin: state => state.auth.hasLoadingLogin
		    })
	    },

		created () {
		    this.SET_LAYOUT(this.KEY_LAYOUT_LOGIN);
		},

		methods: {
		    ...mapMutations({
			    SET_LAYOUT: 'SET_LAYOUT'
		    }),
		    ...mapActions({
			    AUTH_LOGIN: 'AUTH_LOGIN'
		    }),

			onLogin () {
				this.$validator.validate().then(result => {
					if (result) {
					  this.AUTH_LOGIN(this.formData).then(() => {
						this.$router.push({name: 'dashboard'});

					  }).catch(err => {
						  $fn.setValidateErrors(err, this.errors);
					  });
					}
				});
			}
		}
	};
</script>

<style scoped lang=scss>
	.login {
		max-width: 550px;
		width: 100%;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		justify-content: center;
		flex-grow: 1;
		&__error {
			height: 30px;
			text-align: center;
		}
	}
</style>
