<template>
	<div class="element">
		<label :for="name" class="element__label" v-if="label">{{label}} <i v-if="hasRequired">*</i></label>

		<div class="element__wrap">
			<div class="element__mask">{{file ? file.name : value || 'Выберите файл'}}</div>
			<input type="file"
					class="element__area"
					:class="{'has-disabled': disabled}"
					:id="name"
					:name="name"
					@change="processFile($event)"
					:placeholder="placeholder">
		</div>


		<span v-if="errors.has(name)" class="element__error">{{errorText || errors.first(name) }}</span>
	</div>
</template>

<script>
	export default {
		name: 'form-file',
		inject: ['$validator'],
		props:['value', 'name', 'placeholder', 'label','rules', 'disabled', 'errorText'],
		data(){
			return {
				file: null,
			}
		},
		computed:{
			hasRequired(){
				if (this.rules && this.rules.indexOf('required') != -1) return true;
				return false;
			}
		},

		methods: {
			processFile(event){
				var files = event.target.files || event.dataTransfer.files;
				if (!files.length)
					return;
				
				this.file = files[0];
				this.$emit('saveFile', this.file)
			}
		}
	};
</script>

<style scoped lang=scss>
	.element{
		&__wrap{
			position: relative;
		}
		&__mask{
			height: 40px;
			width: 100%;
			border: 1px solid $c_border;
			border-radius: 5px;
			padding: 5px 10px;
			color: #000;
		}
		&__area{
			opacity: 0;
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			cursor: pointer;
			width: 100%;
			height: 100%;
		}

	}
</style>
