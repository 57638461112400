<template>
	<div class="picker">
		<div class="picker__label">{{label}}</div>
		<div class="picker__main">
			<color-picker v-model="colors"
					@input="onUpdate"></color-picker>
		</div>
	</div>
</template>

<script>
	import { Sketch } from 'vue-color';

	export default {
		name: 'form-color-picker',
		components: {
			'color-picker': Sketch,
		},
		props: ['value', 'label'],
		data(){
			return {
				colors: {
					hex: this.value
				}
			}
		},

		methods:{
			onUpdate(){
				this.$emit('input', this.colors.hex);
			}
		}
	};
</script>

<style scoped lang=scss>
	.picker{
		&__label{
			margin-bottom: 5px;
		}
	}
</style>
