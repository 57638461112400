import {kebabCase, camelCase, concat, find, findIndex, filter, assign, debounce, forIn, map, cloneDeep } from 'lodash';
import Vue from 'vue';
let fn = {};


fn.setValidateErrors = (err, validateErrors) => {
  let list = err.response ? err.response.data.errors : [];
  forIn(list, (item,key) => {
	validateErrors.add({
	  field: key,
	  msg: item[0]
	});
  });
};


fn.hasAccess = (key, action) => {
	//control module keys
	let mapKeys = {
		'catalog': 'typographies-catalog',
		'clients': 'typographies-clients',
		'blogs': 'typographies-blogs',
		'reviews': 'typographies-reviews',
		'pages': 'typographies-pages',
		'products': 'typographies-products',
		'products-kinds': 'typographies-products-kinds'
	};

	let moduleKey = mapKeys[key] || key;
	let module = window.$permissions ? window.$permissions.admin[moduleKey] || window.$permissions.admin[key] : null;

	if(!module) return false;

	if(action === 'index') return true;

	return module.indexOf(action) !== -1;
};

fn.map = map;
fn.cloneDeep = cloneDeep;
fn.forIn = forIn;
fn.debounce = debounce;
fn.assign = assign;
fn.find  = find ;
fn.findIndex  = findIndex ;
fn.concat  = concat ;
fn.filter  = filter ;
fn.kebabCase  = kebabCase ;
fn.camelCase  = camelCase ;

window.$fn = fn;
