export default {
	namespaced: true,
	state: {
		PRIZE_TYPE_COINS: 'dark_coins',
		PRIZE_TYPE_RANK: 'rank',
		PRIZE_TYPE_CASHBACK: 'cashback_percent',
		PRIZE_TYPE_DISCOUNT: 'discount_percent',
		PRIZE_TYPE_EX: 'experience',
		PRIZE_TYPE_ACHIEVEMENT: 'achievement',
	}
}
