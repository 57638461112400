<template>
	<div class="vmodal vmodal--ease">
		<button class="vmodal__close" @click="$emit('close')">
			<v-icon name="times"></v-icon>
		</button>

		<div class="vmodal__container">
			<div class="vmodal__main">
				<div class="form">
					<div class="form__head">
						<div class="form__title">Add booster to order</div>
					</div>

					<div class="form__item">
						<form-select v-model="booster"
								:options="boosters"
								rules="required"
								label="Selected booster"
								option_label="name"
								name="booster"></form-select>
					</div>

					<div class="form__footer">
						<button class="btn"
								:class="{'has-loading': loadingSaveItem}"
								@click="onSave"><span>Add</span></button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState, mapGetters, mapMutations, mapActions, } from 'vuex';

	export default {
		name: 'modal-add-booster',
		props:['order'],
		data(){
			return {
				booster: null
			}
		},

		computed:{
			...mapState({
				boosters: state => state.orders.boosters,
				loadingSaveItem: state => state.orders.loadingSaveItem
			})
		},

		created(){
			this.booster = this.order.booster
		},

		methods:{
			...mapActions({
				ADD_BOOSTER_ORDER: 'orders/ADD_BOOSTER_ORDER'
			}),

			onSave(){
				this.$validator.validate().then(result => {
					if (result) {
						let formData = $fn.cloneDeep(this.formData);

						this.ADD_BOOSTER_ORDER({
							id: this.order.id,
							status: this.order.status,
							booster_id: this.booster ? this.booster.id : null

						}).then(() => {
							this.$emit('close');
						});
					}
				});
			}
		}
	};
</script>

<style scoped lang=scss>

</style>
