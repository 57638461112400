import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
import {forIn} from 'lodash';

import auth from '@/store/auth';
import constant from '@/store/constant';

import pages from './modules/pages';
import translates from './modules/translates';
import blogs from './modules/blogs';
import faqs from './modules/faqs';

import orders from './modules/orders/index.js';

import translateScopes from './modules/technicalData/translateScopes';
import languages from './modules/technicalData/languages';

import games from './modules/games/index.js';
import services from './modules/services/index.js';

export default new Vuex.Store({
	modules: {
		auth,
		constant,

		pages,
		translates,
		blogs,
		faqs,
		orders,

		translateScopes,
		languages,

		games,
		services
	},

	state: {
		WEBSITE_URL: process.env.VUE_APP_WEBSITE_URL,

		KEY_LAYOUT_DEFAULT: 'default-layout',
		KEY_LAYOUT_LOGIN: 'login-layout',
		KEY_LAYOUT_ERROR: 'error-layout',

		PRIZE_TYPE_COINS: 'dark_coins',
		PRIZE_TYPE_RANK: 'rank',
		PRIZE_TYPE_CASHBACK: 'cashback_percent',
		PRIZE_TYPE_DISCOUNT: 'discount_percent',
		PRIZE_TYPE_EX: 'experience',
		PRIZE_TYPE_ACHIEVEMENT: 'achievement',

		DEFAULT_LANG: {
			code:"en",
			name:"English"
		},

		layout: 'default-layout',
		selectedLang : 'en',
		selectedLangObj : {
			code:"en",
			name:"English"
		},
		hasOpenMenu: true,

		currencies: [],
		settings: []
	},

	mutations: {
		SET_LAYOUT (state, payload) {
			state.layout = payload;
		},

		SET_OPEN_MENU (state, status) {
			state.hasOpenMenu = status;
		},

		SET_SELECTED_LANG (state, selectedLang) {
			state.selectedLang = selectedLang;
		},

		SET_CURRENCIES(state, list){
			state.currencies = list;
		},

		SET_SETTINGS(state, list){
			state.settings = list;
		},
	},

	actions:{
		GET_CURRENCIES: async({rootState, rootGetters, state, getters, commit}) => {
			try {
				let resp = await $axios.get(`/api/currencies`);

				commit('SET_CURRENCIES', resp.list);

				return resp;
			} catch (e) { throw e; } finally {
			}
		},

		GET_SETTINGS: async({rootState, rootGetters, state, getters, commit}) => {
			try {
				let resp = await $axios.get(`/api/settings`);

				commit('SET_SETTINGS', resp.list);

				return resp;
			} catch (e) { throw e; } finally {
			}
		},
	}
});
