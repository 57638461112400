<template>
	<div class="editor" :class="{'has-hidden': toogleView}">
		<div class="editor__head">
			<div class="editor__title">{{label}}</div>
				<form-checkbox name="viewToogler" @change="setSourceView()" class="showToogler" label="Source code" checked></form-checkbox>
		</div>
		<ckeditor :editor="editor"
				:value="editorData"
				@input="$emit('input', $event)"
				:config="editorConfig"></ckeditor>
		<textarea v-if="toogleView" v-model="sourceData" @input="onInputChange()" class="editor__textarea"></textarea>
	</div>
</template>

<script>
	import CKEditor from '@ckeditor/ckeditor5-vue';
	import * as customEditor from './ckeditor5/build/ckeditor';

	import FormCheckbox from '@form/FormCheckbox';

	import UploadAdapter from './ckeditor5/UploadAdapter.js';

	export default {
		name: 'form-editor',
		components:{
			ckeditor: CKEditor.component,
			FormCheckbox
		},
		props: {
			value: {
				required: true
			},
			label: String,
		},
		watch:{
			value(){
				this.editorData = this.value
			},
		},

		data(){
			return {
				editor: customEditor,
				editorData: this.value,
				sourceData: this.value,
				toogleView: false,

				editorConfig: {
					heading: {
						options: [
							{ model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
							{ model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
							{ model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
							{ model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
							{ model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' }
						]
					},
                    image: {
                         toolbar: [ 'imageTextAlternative', '|', 'imageStyle:alignLeft', 'imageStyle:full', 'imageStyle:alignRight', 'linkImage'  ],

                        styles: [
                            'full',
                            'alignLeft',
                            'alignRight',
                        ],
						
                    },
					table: {
						contentToolbar: [
							'tableColumn',
							'tableRow',
							'mergeTableCells'
						]
					},
					toolbar: {
						items: [
							'heading', '|',
							'alignment', '|',
							'bold', 'italic', 'fontColor', '|',
							'link', '|',
							'bulletedList', 'numberedList', 
							'insertTable', '|',
							'outdent', 'indent', '|',
							'imageUpload', 'blockQuote', '|',
							'undo', 'redo'
						],
						shouldNotGroupWhenFull: false
					},
					extraPlugins: [this.uploader],
				}
			}
		},

		methods:{
			uploader(editor){
				editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
					return new UploadAdapter( loader );
				};
			},
			setSourceView(){
				this.toogleView = !this.toogleView;
			},
			onInputChange(){
				this.$emit('input', this.sourceData);
				this.editorData = this.sourceData;
			}		
		}
	};
</script>

<style scoped lang=scss>
	.editor{
		&__head{
			display: flex;
			justify-content: space-between;
		}
		&__title{
			color: rgba(0, 0, 0, 0.3);
			font-size: 14px;
		}
		&__textarea{
			position: relative;
			min-height: 30rem;
			width: 100%;
			resize: none;
		}
	}
	.has-hidden{
		:only-child{
			display: none;
		}
	}
	.showToogler{
		color: rgba(0, 0, 0, 0.3);
		font-size: 14px;
		:only-child{
			display: flex !important;
			flex-direction: row-reverse;
		}
	}
</style>
