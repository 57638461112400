export default {
	namespaced: true,
	state: {
		list: [],

		loadingGetList: false
	},

	getters: {

	},

	mutations:{
		SET_LIST(state, list){
			state.list = list;
		},

		SET_LOADING_STATUS(state, {value_key, status}){
			state[value_key] = status;
		}
	},

	actions: {
		GET_LIST: async({state, getters, commit}) => {
			try {
				commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

				let resp = await $axios.get('/api/languages');

				commit('SET_LIST', resp.list);

				return resp;
			} catch (e) { throw e; } finally {
				commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
			}
		}
	}

};
