<template>
	<div class="header hide-typography"
			:class="{'has-hide': !hasOpenMenu}">
		<div class="header__head">
			<div class="header__logoWrap">
				<router-link :to="{name: 'dashboard'}" class="header__logo">
					<div><span>DARKBOOSTING</span></div>

					<div class="header__letter">D</div>
				</router-link>
			</div>
		</div>

		<div class="header__main">
			<header-menu></header-menu>
		</div>
	</div>
</template>

<script>
	import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';
	import HeaderMenu from './HeaderMenu';

	export default {
		name: 'header-index',
		components: {
			HeaderMenu
		},
		data(){
			return{
				window: {
					width: 0,
				}
			}
		},
		created() {
			this.handleResize();
		},
		computed: {
			hasOpenMenu: {
				get () {
					return this.$store.state.hasOpenMenu;
				},
				set (status) {
					this.SET_OPEN_MENU(status);
				}
			},
		},

		methods:{
			...mapMutations({
				SET_OPEN_MENU: 'SET_OPEN_MENU',
			}),
			handleResize() {
				this.window.width = window.innerWidth;
				this.window.width <= 768 ? this.hasOpenMenu = !this.hasOpenMenu : null;
			}
		}
	};
</script>

<style scoped lang=scss>
	.header {
		position: fixed;
		left: 0;
		width: 250px;
		height: 100%;
		top: 0;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		/* box-shadow: 2px 0 8px rgba(0, 0, 0, 0.5); */
		transition: all 0.3s;
		padding-top: 80px;
		padding-bottom: 20px;
		user-select: none;
		&.has-hide {
			width: 68px;
			.header__head {
				padding: 0 10px;
			}
			.header__main {
				padding: 0 10px;
			}
			.header__logo {
				width: 100%;
				span {
					opacity: 0;
					visibility: hidden;
				}

			}
			.header__letter{
				opacity: 1;
				visibility: visible;
			}
			.header__logoWrap {
				display: block;
			}
			.user__show {
				opacity: 0;
				visibility: hidden;
			}
			.user__name {
				opacity: 0;
				visibility: hidden;
			}
			.user__logo {
				width: 100%;
				img {
					max-width: 100%;
				}
			}
			@media (max-width: $md) {
				width: 0;
				.header__head {
					padding: 0;
				}
				.header__main {
					padding: 0;
				}
			}
		}
		&.hide-typography{
			padding-top: 80px;
			.header__head{
				background-color: #3CAAFF;
			}
		}
		&:before {
			content: '';
			display: block;
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background:#222222;
		}
		&__head {
			position: absolute;
			left: 0;
			width: 100%;
			top: 0;
			transition: all 0.3s;
		}
		&__main {
			position: relative;
			height: 100%;
			overflow-y: auto;
			overflow-x: hidden;
			transition: all 0.3s;
			&::-webkit-scrollbar {
				width: 1px;
				background-color: transparent;
			}
			&::-webkit-scrollbar-track {
				border-radius: 5px;
				background-color: transparent;
			}
			&::-webkit-scrollbar-thumb {
				border-radius: 5px;
				-webkit-box-shadow: inset 0 0 6px $c_main_light_1;
				background-color: $c_main_light_1;
			}
		}
		&__logo {
			white-space: nowrap;
			text-transform: uppercase;
			color: #fff;
			letter-spacing: 5px;
			word-spacing: 7px;
			width: 100%;
			font-size: 19px;
			padding: 15px 0;
			font-weight: bold;
			font-family: $f_alt;
			display: inline-block;
			&:hover {
				i {
					color: $c_alt;
				}
			}
			span {
				transition: all 0.3s;
				font-weight: normal;
			}
			i {
				transition: all 0.3s;
				font-style: initial;
			}
		}
		&__logoWrap {
			display: flex;
			justify-content: center;
			text-align: center;
			position: relative;
		}
		&__letter{
			display: flex;
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			align-items: center;
			justify-content: center;
			opacity: 0;
			visibility: hidden;
		}
		&__user {
			border-top: 1px solid $c_border_light;
			border-bottom: 1px solid $c_border_light;
		}
	}

	.user {
		display: flex;
		align-items: center;
		padding: 10px 0;
		/*padding-right: 20px;*/
		position: relative;
		justify-content: center;
		&__logo {
			line-height: 0;
			flex-shrink: 0;
			transition: all 0.3s;
			text-align: center;
			img {
				max-width: 100px;
				max-height: 50px;
				transition: all 0.3s;
			}
			&:hover {
				opacity: 0.8;
			}
			span{
				line-height: 1.2;
				display: block;
				text-align: center;
				color: #fff;
				text-transform: uppercase;
				font-size: 14px;
			}
		}
		&__name {
			color: #fff;
			font-size: 14px;
			letter-spacing: 2px;
			transition: all 0.3s;
		}
		&__show {
			position: absolute;
			right: 5px;
			line-height: 0;
			top: 27px;
			opacity: 1;
			visibility: visible;
			svg {
				fill: #fff;
				&:hover {
					fill: $c_alt;
				}
			}
		}
	}
</style>
