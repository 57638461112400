import Vue from './vue_base';
import moment from 'moment';

Vue.filter('numberNormalize', function (value) {
  if (!value) return '';

  if (value - Math.floor (value)){
	return value;

  } else {
	return Math.trunc(value);
  }
});

Vue.filter('numberInteger', function (value) {
	if (!value) return '';

	return Math.floor(value)
});

Vue.filter('numberPrice', function (number, fixed) {
	if (!number) return 0;

	number = String(number);
	let split = number.split('.');

	if (split.length > 1) {
		let left = split[0];
		let right = split[1].substr(0, (!fixed ? 2 : fixed));
		return Number(left + (fixed !== 0 ? '.' + right : ''));

	} else {
		return Number(number);
	}
});

Vue.filter('dateNormalize', function (value) {
	if (!value) return '';

	return moment(value).subtract(1, 'days').format("YYYY-MM-DD");
});
