import { render, staticRenderFns } from "./FormSelectDefault.vue?vue&type=template&id=0a387021&scoped=true&"
import script from "./FormSelectDefault.vue?vue&type=script&lang=js&"
export * from "./FormSelectDefault.vue?vue&type=script&lang=js&"
import style0 from "./FormSelectDefault.vue?vue&type=style&index=0&id=0a387021&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a387021",
  null
  
)

export default component.exports