<template>
	<div class="input" :class="{'has-full': hasFull, 'has-error': errors.has(name)}">

		<multiselect
				v-model="search"
				:options="options"
				:taggable="true"
				:close-on-select="true"
				:clear-on-select="false"
				:preserve-search="true"

				tag-placeholder="Нажмите чтобы создать"
				selectLabel="Нажмите чтобы выбрать"
				deselectLabel="Нажмите чтобы создать"
				:placeholder="labelText"

				:hideSelected="true"
				@tag="addTag"
				@input="onChange"
				@open="hasOpen = true"
				@close="hasOpen = false"
				:label="option_label"
				:track-by="option_id || 'id'"
		>
			<template slot="selection" slot-scope="{ values, search, isOpen }">
				<span class="multiselect__single" v-if="search.length &amp;&amp; !isOpen">{{ search.length }} options selected</span>
			</template>
		</multiselect>

		<!--<div class="input__clear" v-if="value && !notEmpty" @click="onClear">-->
			<!--<v-icon name="times"></v-icon>-->
		<!--</div>-->

		<div class="input__clear input__clear--down" v-if="notEmpty">
			<v-icon name="sort-down"></v-icon>
		</div>

		<label class="input__label" v-if="!hasOpen" :class="{'has-show': hasOpen}">{{labelText}}</label>

		<span v-if="errors.has(name)" class="input__error">{{errorText || errors.first(name) }}</span>
	</div>
</template>

<script>
	import 'vue-awesome/icons/times';
	import 'vue-awesome/icons/sort-down';
	import VIcon from 'vue-awesome/components/Icon';

	import Multiselect from 'vue-multiselect';

	export default {
		name: 'form-select-or-add',
		components: {
			VIcon,
			Multiselect
		},
		inject: ['$validator'],
		props:{
			value: {
				required: true
			},
			options: {
				type: Array,
				required: true
			},
			hasLoadOptions: {
				default: true,
				type: Boolean
			},
			name: {
				type: String,
				required: true
			},
			option_label: String,
			option_id: String,
			type: String,
			label: String,
			rules: String,
			errorText: String,
			notEmpty: Boolean
		},
		data(){
			return {
				text: null,
				search: this.value,
				hasActive: false,
				hasOpen: false
			}
		},

		computed:{
			hasFull(){
				return !!this.value;
			},

			labelText(){
				return !this.hasRequired ? this.label : this.label + '*';
			},

			hasRequired(){
				if (this.rules && this.rules.indexOf('required') != -1) return true;
				return false;
			}
		},

		methods:{
			addTag(val){
				this.search = {
					[this.option_label]: val
				};
				this.$emit('input', this.search)
			},

			onChange(val){
				this.$emit('input', this.search)
			},
			
			onClear(){
				this.search = null;
			}
		}
	};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang=scss>
	@import '~@css_template/input.scss';

	.input{
		position: relative;
		&.has-calc{
			.input__label{
				color: $c_main;
			}
		}
		&.has-full{
			.multiselect__select{
				&:before{
					/*display: none;*/
				}
			}
		}
		&--select{
			.input__label{
				z-index: 2;
				display: none;
				&.has-active{
					display: block;
				}
			}

			&.has-sm{
				.multiselect__content-wrapper{
					overflow-y: auto;
					overflow-x: hidden;
				}
				.multiselect__option{
					font-size: 11px;
				}
			}
		}
		&__clear {
			width: 40px;
			height: 30px;
			position: absolute;
			z-index: 1;
			right: 0;
			top: 15px;
			background: #fff;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			line-height: 0;
			&:hover {
				svg {
					fill: $c_alt;
				}
			}
			svg{
				fill: $c_border;
			}
			&--down{
				pointer-events: none;
			}

		}

	}

	.multiselect__tags{
		border: none;
		border-bottom: 1px solid $c_border;
		border-radius: 0;
		padding-left: 0;
		background: none;
		padding-top: 4px;
	}
	.multiselect__placeholder{
		padding-left: 0;
		font-size: 14px;
		color: $c_text_light;
		font-weight: normal;
	}
	.multiselect__single{
		margin-bottom: 0;
		line-height: 35px;
		background: none;
		cursor: pointer;
	}
	.multiselect__input{
		padding-left: 0;
		margin-bottom: 0;
		line-height: 35px;
		background: none;
	}
	.input__clear{
		background: none;
	}
	.multiselect__content-wrapper{
		&::-webkit-scrollbar {
			width: 12px;
			background-color: #F5F5F5;
		}
		&::-webkit-scrollbar-track {
			-webkit-box-shadow: inset 0 0 6px $c_main_light_3;
			border-radius: 5px;
			background-color: #F5F5F5;
		}
		&::-webkit-scrollbar-thumb {
			border-radius: 5px;
			-webkit-box-shadow: inset 0 0 6px $c_main_light_3;
			background-color: #9f9f9f;
		}
	}

	.multiselect__single{
		padding-left: 0;
		font-size: 14px;
		color: $c_text;
		font-weight: normal;
	}
</style>
