import Vue from 'vue';
import VueRouter from 'vue-router';

import Dashboard from '@views/dashboard/DashboardIndex.vue';
import LoginIndex from '@views/LoginIndex';

import store from '../store';

Vue.use(VueRouter);

const routes = [
	{
		path: '/login',
		name: 'login',
		component: LoginIndex,
		meta: {
			layout: store.state.KEY_LAYOUT_LOGIN,
			requiresFree: true
		}
	},
	{
		path: '/',
		name: 'dashboard',
		component: Dashboard,
	},

	{
		path: '/pages',
		name: 'pages',
		component: () => import(/* webpackChunkName: "pages" */ '@views/pages/PagesIndex'),
		meta:{
		}
	},
	{
		path: '/blogs',
		name: 'blogs',
		component: () => import(/* webpackChunkName: "blogs" */ '@views/blogs/BlogsIndex'),
		meta:{
		}
	},
	{
		path: '/faq',
		name: 'faq',
		component: () => import(/* webpackChunkName: "faq" */ '@views/faqs/FaqsIndex'),
		meta:{
		}
	},
	{
		path: '/translates',
		name: 'translates',
		component: () => import(/* webpackChunkName: "translates" */ '@views/translates/TranslatesIndex'),
		meta:{
		}
	},

	//games
	{
		path: '/games',
		name: 'game',
		component: () => import(/* webpackChunkName: "game" */ '@views/games/GameIndex'),
	},
	{
		path: '/games/:id',
		name: 'gameItem',
		component: () => import(/* webpackChunkName: "gameItem" */ '@views/games/GameItem'),
		props: true
	},
	{
		path: '/service/:id?',
		name: 'service',
		component: () => import(/* webpackChunkName: "service" */ '@views/services/ServiceIndex'),
		props: true
	},
	{
		path: '/orders',
		name: 'orders',
		component: () => import(/* webpackChunkName: "pages" */ '@views/orders/OrdersIndex'),
		meta:{
	}
	},
	{
		path: '/orders/:id',
			name: 'ordersItem',
		component: () => import(/* webpackChunkName: "ordersItem" */ '@views/orders/OrderItem'),
		props: true
	},

	//technicalData
	{
		path: '/languages',
		name: 'languages',
		component: () => import(/* webpackChunkName: "languages" */ '@views/technicalData/languages/LanguagesIndex')
	},
	{
		path: '/translate-scopes',
		name: '/translate-scopes',
		component: () => import(/* webpackChunkName: "/translate-scopes" */ '@views/technicalData/translateScopes/TranslateScopesIndex')
	},


	//booster
	{
		path: '/rules',
		name: 'rules',
		component: () => import(/* webpackChunkName: "rules" */ '@views/rules/RulesIndex'),
		meta:{
		}
	},
	{
		path: '/cabinet',
		name: 'cabinet',
		component: () => import(/* webpackChunkName: "cabinet" */ '@views/cabinet/CabinetIndex'),
		meta:{
		}
	},


	//404-------------------------/
	{
		path: '*',
		redirect: '/404'
	},
	{
		path: '/404',
		component: () => import(/* webpackChunkName: "404" */ '@views/error404'),
		meta: {
			layout: store.state.KEY_LAYOUT_ERROR
		}
	}
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior (to, from, savedPosition) {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve({x: 0, y: 0});
			}, 200);
		});
	}
});

router.beforeEach((to, from, next) => {
	if (to.meta.requiresFree === true) {
		next();

	} else {
		if (store.getters.hasLogged){

			if (to.meta.permissionKey){
				let permissions =  window.$permissions || JSON.parse(localStorage.getItem('_permissions_druk_crm')) || null;

				let keys = to.meta.permissionKey.split(',');

				let access = false;
				keys.forEach(el => {
					if (permissions.admin[el]) access = true;
				})
				
				if(permissions &&  access){
					next()
				} else{
					next({name: 'dashboard'})
				}

			} else {
				next()
			}


		} else {
			next({name: 'login'});
		}
	}
});

export default router;
