<template>
	<div class="table" :class="{'has-loading': hasLoadList}">
		<table>
			<thead>
				<tr>
					<th v-for="head in headers" v-if="!head.hideTh">{{head.text}}</th>
				</tr>
			</thead>

			<tbody>
				<slot></slot>
			</tbody>
		</table>

		<div class="table__empty" v-if="!items.length && !hasLoadList">Список пуст ...</div>

		<div class="table__footer" v-if="hasShowPagination">
			<vue-pagination
					:pagination="pagination"
					@changeLimit="$emit('changeLimit', $event)"
					@changePage="$emit('changePage', $event)"></vue-pagination>
		</div>

		<div class="table__loading">
			<!--<v-icon name="sync-alt" spin></v-icon>-->
		</div>
	</div>
</template>

<script>
	import 'vue-awesome/icons/trash-alt';
	import 'vue-awesome/icons/edit';
	import 'vue-awesome/icons/eye';
	import 'vue-awesome/icons/sync-alt';
	import VIcon from 'vue-awesome/components/Icon';

	import VuePagination from '@components/VuePagination';

	export default {
		name: 'v-table',
		components: {
			VIcon,
			VuePagination
		},
		props: {
			headers:{
				type: Array,
				required: true
			},
			items:{
				type: Array,
				required: true
			},
			pagination: Object,
			navigations: Array,
			hasLoadList: Boolean,
			routeLink: String
		},

		computed: {
			hasShowPagination(){
				if (this.hasLoadList || !this.pagination) return false;
				return this.pagination.total > this.items.length;
			},

			hasNavigations(){
				if (!this.navigations) return 0;
				return this.navigations.length;
			},

			hasNavEdit(){
				return this.navigations.indexOf('edit') !== -1;
			},

			hasNavEditLink(){
				return this.navigations.indexOf('editLink') !== -1;
			},

			hasNavShow(){
				return this.navigations.indexOf('show') !== -1;
			},

			hasNavDelete(){
				return this.navigations.indexOf('delete') !== -1;
			}
		}
	};
</script>

<style scoped lang=scss>
	@import '~@css/template/table.scss';
</style>
