<template>
	<div id="app">
		<component :is="layout"></component>
	</div>
</template>

<script>
	import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';

	import DefaultLayout from '@layouts/DefaultLayout';
	import LoginLayout from '@layouts/LoginLayout';
	import ErrorLayout from '@layouts/ErrorLayout';

	export default {
		name: 'app',
		components: {
			DefaultLayout,
			LoginLayout,
			ErrorLayout
		},

		computed: {
			...mapState({
				layout: state => state.layout,
			}),
		},

		watch: {
			$route (to, from) {
				this.setLayout(to.meta.layout || 'default-layout');
			}
		},

		created () {
			this.initCommonData();
		},

		methods: {
			...mapMutations({
				SET_LAYOUT: 'SET_LAYOUT'
			}),
			...mapActions({
				GET_LANGUAGES: 'languages/GET_LIST',
			}),

			setLayout (newLayout) {
				if (this.layout === newLayout) {
					return false;
				}
				this.$store.commit('SET_LAYOUT', newLayout);
			},

			initCommonData(){
				this.GET_LANGUAGES();
			}
		}
	};
</script>

<style lang="scss">
	@import '~@css/reset.scss';
	@import '~@css/common.scss';
	@import '~@css_template/title.scss';
	@import '~@css_template/btn.scss';
	@import '~@css_template/form.scss';
	@import '~@css_template/section.scss';
	@import '~@css_template/vmodal.scss';
	@import '~@css_template/lang.scss';
	@import '~@css_template/msg.scss';

	#app {
		height: 100%;
		background-color: #ffffff;
	}
</style>
